//Modules
import { useState, useEffect, createContext, useContext } from "react";
import {
  doc,
  onSnapshot,
  updateDoc,
  deleteField,
  increment,
  setDoc,
} from "firebase/firestore";

//Hooks
import { useAuth } from "../hooks/auth";
import { db } from "../firebase/client";

export const CartContext = createContext();

export default function CartContextComponent({ children }) {
  // Cart toggle state
  const [cartOpen, setCartOpen] = useState(false);
  // Cart state
  const [cart, setCart] = useState({});
  // Hooks
  const { authObject } = useAuth();

  // Fetch cart if the user is signed in
  useEffect(() => {
    if (authObject) {
      const unsub = onSnapshot(
        doc(db, "carts", authObject.uid),
        (doc) => {
          setCart(doc.data());
        },
        (err) => {
          console.log(err);
        }
      );
      return () => {
        unsub();
      };
    } else {
      setCart({});
    }
  }, [authObject]);

  // Are all these fields correct, do we need to send more
  const addItem = async (product, quantity) => {
    return await updateDoc(doc(db, "carts", authObject.uid), {
      [product.FullName]: {
        quantity,
        RecordID: product.RecordID,
        WDCatalogImageFileName: product.WDCatalogImageFileName || null, //TODO - Make sure all items have catalog images
        WDCatalogImage: product.WDCatalogImage || null, //TODO - Make sure all items have catalog images
        eCommerceURL: product.eCommerceURL || null,
        ItemWeight: product.ItemWeight || null,
        PurchaseCost: product.PurchaseCost || null, // For calculating insurance cost of shipping packages
        SalesPrice: product.SalesPrice || null,
        SalesDesc: product.SalesDesc || null, // For customs information needed by ShipEngine API
        SKU: product.FullName || null, //TODO - Make sure all items have sku's
        eCommerceStoreName: product.eCommerceStoreName || null,
        eCommerceCategory: product.eCommerceCategory || null,
      },
    }).catch((err) => console.log(err));
  };

  //SKU and FullName are the same value. FullName will always be a unique value

  const removeItem = async (SKU) => {
    return await updateDoc(doc(db, "carts", authObject.uid), {
      [SKU]: deleteField(),
    }).catch((err) => console.log(err));
  };

  const increase = async (SKU) => {
    let nested = `${SKU}.quantity`;
    return await updateDoc(doc(db, "carts", authObject.uid), {
      [nested]: increment(1),
    });
  };

  const decrease = async (SKU) => {
    let nested = `${SKU}.quantity`;
    return await updateDoc(doc(db, "carts", authObject.uid), {
      [nested]: increment(-1),
    });
  };

  const totalQuantity = () => {
    if (Boolean(Object.keys(cart).length)) {
      let hardware = Object.values(cart)
        .filter((item) => item.eCommerceCategory !== "Software")
        .filter((item) => item.quantity)
        .reduce((acc, item) => acc + item.quantity, 0);

      let software = Object.values(cart)
        .filter((item) => item.eCommerceCategory === "Software")
        .filter((item) => item.quantity)
        .reduce((acc, item) => acc + item.quantity, 0);

      return {
        hardware,
        software,
        total: hardware + software,
      };
    }

    return {
      hardware: 0,
      software: 0,
      total: 0,
    };
  };

  const clearCart = async () => {
    // reset db instance of cart to an empty document.
    return await setDoc(doc(db, "carts", authObject.uid), {});
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartOpen,
        setCartOpen,
        addItem,
        removeItem,
        increase,
        decrease,
        totalQuantity,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const useCart = () => useContext(CartContext);
