//Modules
import { useState, useEffect, createContext, useContext } from "react";
import { useRouter } from "next/router";
import useSWR from "swr";
import axios from "axios";

//Firebase
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/client";

//Func
import auth_fetcher from "../api/auth_fetcher";
import displayAuthError from "../errors/authErrors";

//Hook+
import { useAlert } from "./alert";

export const AuthContext = createContext();

export default function AuthContextComponent({ children }) {
  // Firebase auth object.
  const [authObject, setAuthObject] = useState(null);
  // Waiting for firebase to return
  const [loadingAuth, setLoadingAuth] = useState(false);
  //Creating / signing up a user
  //Flag neccessary as when someone signs up we create firebase account first.
  //We use the flag to stop the method call from occuring until we've created
  //a method profile for the new user.
  const [creatingUser, setCreatingUser] = useState(false);
  // Global Alert
  const { setAlert } = useAlert();

  // Grabbing reference to firebase auth
  const auth = getAuth();

  const router = useRouter();

  function handleError(error) {
    setLoadingAuth(false);
    displayAuthError(error, setAlert);
  }

  // Make shift use effect to monitor method user data.
  // Fetch method data depending on whether the authObject
  // is available. If it is call endpoint with useSWR which
  // will cache the result so endpoint is not hit everytime
  // plus other performance boosts from useSWR
  const { data: userData, error: userDataError } = useSWR(
    authObject && !creatingUser ? ["/api/user", authObject] : null,
    auth_fetcher
  );
  // During the sign up process an AuthObject is created before Method data is created. Causing a userDataError.

  // Set loading flag when auth process finished
  useEffect(() => {
    if (authObject && userData) {
      setLoadingAuth(false);
    }

    if (userDataError) {
      signout(); // Does this need to be here?? For safety perhaps best to logout the user
      setLoadingAuth(false);
    }
  }, [authObject, userData, userDataError]);

  //Listen for authenticated user
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Don't set the entire auth object only the fields we need.
          setAuthObject(user);
        } else {
          setAuthObject(null);
        }
      } catch (error) {
        handleError(error);
      }
    });
    // Unsubscribe auth listener on unmount
    return () => {
      unsub();
    };
  }, [auth]);

  const signin = async ({ email, password }) => {
    setLoadingAuth(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then(() => router.push("/"))
      .catch((error) => handleError(error));
  };

  const signup = async (values) => {
    setLoadingAuth(true);
    setCreatingUser(true);

    // Steps in this function
    // 1) create firebase user
    // 2) create firestore document
    // 3) use firebase uid to create method lead
    // 4) update method contact object with firebase uid (requires 2 api calls)

    try {
      // Create firebase user
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      let uid = userCredential.user.uid;

      //Generate token for API authentication
      let idToken = await userCredential.user
        .getIdToken()
        .then((idToken) => idToken);

      // Create firestore document for user
      await setDoc(doc(db, "carts", uid), {});

      // Create method lead for customer.
      await axios({
        method: "post",
        url: "/api/user",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          Phone: values.phone,
          Name: values.company,
          CompanyName: values.company,
          CustomerType: "General",
          IsLeadStatusOnly: true,
          LeadSource: "Web",
          LeadStatus: "Open",
          LeadRating: "Hot",
        },
      });

      //Cheat and grab the contact recordID associated with the customer that was just created above
      let Contact = await axios({
        method: "get",
        url: `/api/dashboard/contact?Email=${values.email}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      // update the users uid in method
      await axios({
        method: "patch",
        url: "/api/dashboard/contact",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          RecordID: Contact.data.value[0].RecordID,
          Contact: { WebPortalUID: uid },
        },
      });

      // email notification to employees
      const email_api_body = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: values.email,
        Phone: values.phone,
        CompanyName: values.company,
        CustomerType: "General",
      };

      let email_api = await axios({
        method: "post",
        url: "/api/email/new_account",
        headers: {
          "Content-Type": "application/json",
          Authorization: "2jHCdX4Uc3p0ZXB",
        },
        data: email_api_body,
      });

      // add activity in method

      // redirect the user
      setCreatingUser(false);
      router.push("/");
    } catch (error) {
      // providing for method errors
      // if (!error.code) {
      //   error.code = error.response.data.detail.substring(0, 4);
      // }
      // 1)  data: {title: 'Bad Request', status: 400,detail: 'B001 - Save Warning: The Customer/Employee/Vendor/OtherName could not be sent to QuickBooks.  This Name is already in use.  Please edit the Name so that it is unique and save again.',
      handleError(error);
    }
  };

  const legacySignup = async ({ email, password, recordID }) => {
    setCreatingUser(true);

    try {
      // Create firebase user
      let userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      let uid = userCredential.user.uid;

      //Generate token for API authentication
      let idToken = await userCredential.user
        .getIdToken()
        .then((idToken) => idToken);

      // Create firestore document for user
      await setDoc(doc(db, "carts", uid), {});

      // update the users uid in method
      await axios({
        method: "patch",
        url: "/api/dashboard/contact",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          RecordID: recordID,
          Contact: { WebPortalUID: uid },
        },
      });

      // redirect the user
      setCreatingUser(false);
      router.push("/");
      setAlert({
        title: "Account successfully migrated",
        message:
          "Your account was successfully migrated, you can now login normally",
        type: "success",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setAlert({
        title: "Email successfully sent",
        message: "Please open the email in your inbox to reset your password",
        type: "success",
      });
      setLoadingAuth(false);
    } catch (error) {
      handleError(error);
    }
  };

  const checkIfMethodUserExists = async (email) => {
    try {
      let result = await axios({
        method: "get",
        url: `/api/emailcheck?Email=${email}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "e7XLA85Z2x1Kgac",
        },
      });
      return result.data;
    } catch (error) {
      if (error.response.status === 403) {
        setAlert({
          title: "Account already exists",
          message: "This account already exists, please sign in normally",
          type: "error",
        });
      } else if (error.response.status === 418) {
        setAlert({
          title: "Multiple Accounts Found",
          message:
            "Multiple accounts found under this email address. Please contact support.",
          type: "error",
        });
      } else if (error.response.status === 406) {
        setAlert({
          title: "Account error",
          message:
            "This account may not be setup correctly. Please contact support.",
          type: "error",
        });
      } else {
        setAlert({
          title: "Error",
          message: "Whoops something went wrong, please try again",
          type: "error",
        });
      }
      return { error: true };
    }
  };

  const signout = () => {
    return signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        authObject,
        signin,
        signout,
        signup,
        legacySignup,
        loadingAuth,
        setLoadingAuth,
        userData,
        resetPassword,
        checkIfMethodUserExists,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const useAuth = () => useContext(AuthContext);
