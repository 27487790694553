// Function just displays the various auth errors that can occur
// Used to give feedback to the user

const DisplayAuthError = (error, setAlert) => {
  switch (error.code) {
    // =========================
    // Firebase errors
    // =========================
    case "auth/wrong-password":
      setAlert({
        title: "Wrong Password",
        message: "Wrong password, please try again",
        type: "error",
      });
      break;
    case "auth/too-many-requests":
      setAlert({
        title: "To many login attempts",
        message:
          "This account has been temporarily as too many unsuccessful login attempts have been made. Either reset your password or try again later",
        type: "error",
      });
      break;
    case "auth/user-not-found":
      setAlert({
        title: "User not found",
        message:
          "No account found with this email address, please create an account",
        type: "error",
      });
      break;
    case "auth/email-already-in-use":
      setAlert({
        title: "Email is already in use",
        message:
          "An account exists with this email, use another email or try log in",
        type: "error",
      });
      break;

    // =========================
    // Method errors
    // =========================
    case "B001":
      setAlert({
        title: "User",
        message:
          "An account exists with this email, use another email or try log in",
        type: "error",
      });
      break;

    default:
      setAlert({
        title: "Authentication unsuccessful",
        message: "Whoops, something went wrong. Please try again",
        type: "error",
      });
  }
};

export default DisplayAuthError;
