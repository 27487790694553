import { useState, useEffect, createContext, useContext } from "react";

//Hooks
import { useAuth } from "./auth";
import { useCart } from "./cart";

export const PriceContext = createContext();

export default function PriceContextComponent({ children }) {
  const { userData } = useAuth();
  const { cart, totalQuantity } = useCart();

  // Returns array of price points for any given item.
  // ONLY USED TO RENDER PRICING TABLE
  // Hence if only one price point, return empty array so nothing is rendered
  const getBasePricing = (ItemFullName) => {
    //Is user authenticated?
    if (userData) {
      let pricingData = userData?.pricing.filter(
        (item) => item.ItemFullName === ItemFullName
      );

      if (pricingData.length > 1) {
        return pricingData;
      } else {
        return [];
      }
    }
    // no special price for someone who isn't authed
    // no no no you must be authed.
    return [];
  };

  // Returns the one true price for any product
  const getPrice = (ItemFullName, category, itemSalesPrice) => {
    // Price depends on
    // 1. International / domestic rates (6+ / 3+) TICK TICK MF
    // 2. Hardware / software ( Category != 'Software')
    // 3. Total quantity of hardware (Category != Software)

    if (userData) {
      // Check to see if the user has any pricing data. If not
      // they just get the normal sales price of the item.
      if (userData.pricing.length === 0) {
        return itemSalesPrice;
      }

      // Grabs prices and lower range on customer type
      // customer type is set on method so pricing here will
      // always match the customer type
      let itemData = userData?.pricing.filter(
        (item) => item.ItemFullName === ItemFullName
      );
      let pricingData = itemData.map((item) => item.SalesPrice2);
      let QuantityLowerRange = itemData.map((item) => item.QuantityLowerRange);

      if (pricingData.length === 1) {
        return pricingData[0];
      }

      //Top level check if hardware or software
      if (category == "Software") {
        // Checks if total software quantity in cart is over the
        // lower range and if it does applies the discounted price
        // to all software products
        if (
          pricingData.length > 1 &&
          totalQuantity().software >= Math.max(...QuantityLowerRange)
        ) {
          return Math.min(...pricingData);
        }
        // Checks if total software quantity in cart is under the
        // lower range and if it will apply non-discounted price
        // to all software products
        if (
          pricingData.length > 1 &&
          totalQuantity().software < Math.max(...QuantityLowerRange)
        ) {
          return Math.max(...pricingData);
        }
      } else {
        // Just the same shit as above but for hardware
        if (
          pricingData.length > 1 &&
          totalQuantity().hardware >= Math.max(...QuantityLowerRange)
        ) {
          return Math.min(...pricingData);
        }

        if (
          pricingData.length > 1 &&
          totalQuantity().hardware < Math.max(...QuantityLowerRange)
        ) {
          return Math.max(...pricingData);
        }
      }
    }

    // If no special pricing then just return normal sales price
    return itemSalesPrice;
  };

  const getSubtotal = () => {
    // loop through the cart
    let subTotal = 0;
    // check if there's anything in the cart
    if (Object.keys(cart).length) {
      Object.values(cart).forEach((item) => {
        subTotal +=
          getPrice(item.SKU, item.eCommerceCategory, item.SalesPrice) *
          item.quantity;
      });
    }
    return subTotal.toFixed(2);
  };

  return (
    <PriceContext.Provider
      value={{
        getBasePricing,
        getPrice,
        getSubtotal,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const usePrice = () => useContext(PriceContext);
