import { useState, createContext, useContext } from "react";

export const AlertContext = createContext();

export default function AlertContextComponent({ children }) {
  const [alert, setAlert] = useState(null);

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const useAlert = () => useContext(AlertContext);
