import axios from "axios";

const auth_fetcher = async (url, authObject) => {
  // Get auth token
  const idToken = await authObject.getIdToken().then((idToken) => idToken);
  // Make API request
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  //Return data
  return res.data;
};

export default auth_fetcher;
