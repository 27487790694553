import "tailwindcss/tailwind.css";
import firebase from "../lib/firebase/client";
import "../styles/globals.css";
import Head from "next/head";
import Script from "next/script";

//Components
import AuthContextComponent from "../lib/hooks/auth";
import CartContextComponent from "../lib/hooks/cart";
import PriceContextComponent from "../lib/hooks/price";
import AlertContextComponent from "../lib/hooks/alert";

// Error Context lets us set global errors
// Error component rendered in layout/general.js
function MyApp({ Component, pageProps }) {
  return (
    <AlertContextComponent>
      <AuthContextComponent>
        <CartContextComponent>
          <PriceContextComponent>
            <Head>
              {/* <!-- Primary Meta Tags --> */}
              <title>Wave Distro</title>
              <meta name="title" content="Wave Distro" />
              <meta
                name="description"
                content="Pro Audio Distribution – In The Mix Since '96"
              />

              {/* <!-- Open Graph / Facebook --> */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://store.wavedistro.com/" />
              <meta property="og:title" content="Wave Distro" />
              <meta
                property="og:description"
                content="Pro Audio Distribution – In The Mix Since '96"
              />
              <meta
                property="og:image"
                content="https://store.wavedistro.com/logo/wave-logo-icon.png"
              />

              {/* <!-- Twitter --> */}
              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content="https://store.wavedistro.com/"
              />
              <meta property="twitter:title" content="Wave Distro" />
              <meta
                property="twitter:description"
                content="Pro Audio Distribution – In The Mix Since '96"
              />
              <meta
                property="twitter:image"
                content="https://store.wavedistro.com/logo/wave-logo-icon.png"
              />

              {/* Favicon */}
              <link
                rel="shortcut icon"
                href="https://store.wavedistro.com/logo/favicon.ico"
              ></link>
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="https://store.wavedistro.com/logo/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="https://store.wavedistro.com/logo/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="https://store.wavedistro.com/logo/favicon-16x16.png"
              />
            </Head>
            <Script
              id="help-scout"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});window.Beacon('init', '262842af-7932-45cf-b06c-82d95d484720')`,
              }}
            />
            <Component {...pageProps} />
          </PriceContextComponent>
        </CartContextComponent>
      </AuthContextComponent>
    </AlertContextComponent>
  );
}

export default MyApp;
