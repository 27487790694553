// Client credentials used to access auth
// and  db service from the client.
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const clientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FB_APPLICATION,
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
};

const firebase = initializeApp(clientCredentials);

// Check that `window` is in scope for the analytics module!
// if (typeof window !== "undefined" && !firebase.apps.length) {
//   firebase.initializeApp(clientCredentials);
//   // To enable analytics.
//   if ("measurementId" in clientCredentials) {
//     firebase.analytics();
//     firebase.performance();
//   }
// }

// TODO - Facebook and Google authentication?
// export const google = new firebase.auth.GoogleAuthProvider();
// export const facebook = new firebase.auth.FacebookAuthProvider();

export const db = getFirestore();
export const storage = getStorage();
export default firebase;
